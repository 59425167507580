/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import * as InternalFormatters from './formatters-internal.js'

//map the search experience host to the destination ADS site host
//for relative links
const relativeLinkHostMap = {
  "fr.search.adspipe.ca": "fr.adspipe.ca",
  "search.adspipe.ca": "adspipe.ca",
  "search.adspipe.com" : "www.adspipe.com",
  "master-search-adspipe-com.preview.pagescdn.com": "uat.adspipe.com"
}

//the mapping for language hosts
//if we are on a language search that is defined, then change absolute links if they match the source to the destination
const absoluteLinkHostMap={
    "fr.search.adspipe.ca": [
      { source:"adspipe.ca", destination:"fr.adspipe.ca" }
    ],
    "400c5a1b-cbd.landingpagespreview.com": [
      { source:"adspipe.ca", destination: "canada-en.local" },
      { source:"www.adspipe.com", destination: "us-en.local" },
      { source:"fr.adspipe.ca", destination:"canada-fr.local" }
    ],
    "master-search-adspipe-com.preview.pagescdn.com": [
      { source:"adspipe.ca", destination: "uat.adspipe.ca" },
      { source:"fr.adspipe.ca", destination:"fr.uat.adspipe.ca" },
      { source:"www.adspipe.com", destination: "uat.adspipe.com" }
    ] 
}



export function image(simpleOrComplexImage = {}, desiredSize = '600x', atLeastAsLarge = true) {
  return InternalFormatters.image(simpleOrComplexImage, desiredSize, atLeastAsLarge);
}

export function adsResolveLink(linkUrl) {
  //if linkUrl is something and doesn't start with http (its a relative link)
  if(linkUrl && !linkUrl.startsWith("http")){

    const current = new URL(window.location.href);
    const newBase = relativeLinkHostMap[current.hostname] ?? getDefaultSiteHost();

    try {
      return resolveAbsoluteUrlForLanguage(new URL(linkUrl, `https://${newBase}`).toString());
    } catch { 
      console.log(`${linkUrl} is not a valid link.`)
    };
  }

  return resolveAbsoluteUrlForLanguage(linkUrl);
}

function getDefaultSiteHost() {
  
  try {
    //do our best to support staging environments
    //we support en_CA right now to use a base of uat.adspipe.ca
    if(ANSWERS.core._experienceVersion == "STAGING") {
      if(window.location.href.indexOf("/en_CA/") > 0) {
        return "uat.adspipe.ca";
      } else {
        return "uat.adspipe.com";
      }
    }
  } catch {}

  return "www.adspipe.com";
}

function resolveAbsoluteUrlForLanguage(url) {
  
  const current = new URL(window.location.href);
  const mapped = absoluteLinkHostMap[current.hostname];

  //if the search is on a language host AND the passed in url is absolute
  if(mapped && url && url.startsWith("http")){
    try{
      const linkUrl = new URL(url);
      //if the hostName of the link is equal to the mapped source, then remap it to the destination
      //and return
      for(let mapping of mapped){
        if(linkUrl.hostname == mapping.source){
          linkUrl.hostname = mapping.destination;
          return linkUrl.toString();
        }
      }
    }catch{}
  }

  //return the original url passed in
  return url;

}

//try to get the resolved primary cta link OR the landingPageUrl
export function adsGetDefaultItemUrl(profile){
  return adsResolveLink(Formatter.generateCTAFieldTypeLink(profile.c_primaryCTA) || profile.landingPageUrl);
}
